import {Prisma} from '@prisma/client';
import {registerCustom} from 'superjson';

export function registerPrismaDecimalTransformer() {
  registerCustom<Prisma.Decimal, string>(
    {
      isApplicable: (v: unknown): v is Prisma.Decimal => Prisma.Decimal.isDecimal(v),
      serialize: (v: Prisma.Decimal) => v.toJSON(),
      deserialize: (v: string) => new Prisma.Decimal(v),
    },
    'Prisma.Decimal'
  );
}
