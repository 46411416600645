import type {DeserializedData} from '../types';
import {deserialize} from './deserialize';

/**
 * Utilty function for client fetching from Remix loaders/actions.
 * It expects successful responses to be JSON and unsuccessful
 * responses to be text (e.g. created with `assertWithResponse` helper).
 */
export async function fetchJson<T>(input: RequestInfo | URL, init?: RequestInit) {
  const response = await fetch(input, init);

  if (!response.ok) {
    throw new Error(await response.text());
  }

  const json = await response.json();
  const data = deserialize<DeserializedData<T>>(json);

  return data;
}
